/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MaximumArrivalDateBusinessRuleRequest model module.
 * @module model/MaximumArrivalDateBusinessRuleRequest
 * @version v1
 */
class MaximumArrivalDateBusinessRuleRequest {
    /**
     * Constructs a new <code>MaximumArrivalDateBusinessRuleRequest</code>.
     * @alias module:model/MaximumArrivalDateBusinessRuleRequest
     */
    constructor() { 
        
        MaximumArrivalDateBusinessRuleRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MaximumArrivalDateBusinessRuleRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MaximumArrivalDateBusinessRuleRequest} obj Optional instance to populate.
     * @return {module:model/MaximumArrivalDateBusinessRuleRequest} The populated <code>MaximumArrivalDateBusinessRuleRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MaximumArrivalDateBusinessRuleRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('businessRuleTypeId')) {
                obj['businessRuleTypeId'] = ApiClient.convertToType(data['businessRuleTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('seasonTypeId')) {
                obj['seasonTypeId'] = ApiClient.convertToType(data['seasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'String');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'String');
            }
            if (data.hasOwnProperty('validationMessage')) {
                obj['validationMessage'] = ApiClient.convertToType(data['validationMessage'], 'String');
            }
            if (data.hasOwnProperty('productClassIds')) {
                obj['productClassIds'] = ApiClient.convertToType(data['productClassIds'], ['Number']);
            }
            if (data.hasOwnProperty('locationIds')) {
                obj['locationIds'] = ApiClient.convertToType(data['locationIds'], ['Number']);
            }
            if (data.hasOwnProperty('enforceByLocation')) {
                obj['enforceByLocation'] = ApiClient.convertToType(data['enforceByLocation'], 'Boolean');
            }
            if (data.hasOwnProperty('length')) {
                obj['length'] = ApiClient.convertToType(data['length'], 'Number');
            }
            if (data.hasOwnProperty('unitId')) {
                obj['unitId'] = ApiClient.convertToType(data['unitId'], 'Number');
            }
            if (data.hasOwnProperty('allowReleaseInNextYear')) {
                obj['allowReleaseInNextYear'] = ApiClient.convertToType(data['allowReleaseInNextYear'], 'Boolean');
            }
            if (data.hasOwnProperty('blockReleaseDate')) {
                obj['blockReleaseDate'] = ApiClient.convertToType(data['blockReleaseDate'], 'String');
            }
            if (data.hasOwnProperty('blockReleaseId')) {
                obj['blockReleaseId'] = ApiClient.convertToType(data['blockReleaseId'], 'Number');
            }
            if (data.hasOwnProperty('daysAllowedAfterMaximumArrival')) {
                obj['daysAllowedAfterMaximumArrival'] = ApiClient.convertToType(data['daysAllowedAfterMaximumArrival'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
MaximumArrivalDateBusinessRuleRequest.prototype['id'] = undefined;

/**
 * @member {Number} businessRuleTypeId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['businessRuleTypeId'] = undefined;

/**
 * @member {Boolean} isActive
 */
MaximumArrivalDateBusinessRuleRequest.prototype['isActive'] = undefined;

/**
 * @member {Number} productId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['productId'] = undefined;

/**
 * @member {Number} productTypeId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['productTypeId'] = undefined;

/**
 * @member {Number} loopId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['loopId'] = undefined;

/**
 * @member {Number} salesChannelId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['salesChannelId'] = undefined;

/**
 * @member {Number} seasonTypeId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['seasonTypeId'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} comments
 */
MaximumArrivalDateBusinessRuleRequest.prototype['comments'] = undefined;

/**
 * @member {String} startDate
 */
MaximumArrivalDateBusinessRuleRequest.prototype['startDate'] = undefined;

/**
 * @member {String} endDate
 */
MaximumArrivalDateBusinessRuleRequest.prototype['endDate'] = undefined;

/**
 * @member {String} validationMessage
 */
MaximumArrivalDateBusinessRuleRequest.prototype['validationMessage'] = undefined;

/**
 * @member {Array.<Number>} productClassIds
 */
MaximumArrivalDateBusinessRuleRequest.prototype['productClassIds'] = undefined;

/**
 * @member {Array.<Number>} locationIds
 */
MaximumArrivalDateBusinessRuleRequest.prototype['locationIds'] = undefined;

/**
 * @member {Boolean} enforceByLocation
 */
MaximumArrivalDateBusinessRuleRequest.prototype['enforceByLocation'] = undefined;

/**
 * @member {Number} length
 */
MaximumArrivalDateBusinessRuleRequest.prototype['length'] = undefined;

/**
 * @member {Number} unitId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['unitId'] = undefined;

/**
 * @member {Boolean} allowReleaseInNextYear
 */
MaximumArrivalDateBusinessRuleRequest.prototype['allowReleaseInNextYear'] = undefined;

/**
 * @member {String} blockReleaseDate
 */
MaximumArrivalDateBusinessRuleRequest.prototype['blockReleaseDate'] = undefined;

/**
 * @member {Number} blockReleaseId
 */
MaximumArrivalDateBusinessRuleRequest.prototype['blockReleaseId'] = undefined;

/**
 * @member {Number} daysAllowedAfterMaximumArrival
 */
MaximumArrivalDateBusinessRuleRequest.prototype['daysAllowedAfterMaximumArrival'] = undefined;






export default MaximumArrivalDateBusinessRuleRequest;

