/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Tenant from '../model/Tenant';
import TenantAttributeIEnumerableResponse from '../model/TenantAttributeIEnumerableResponse';
import TenantRegionsResponse from '../model/TenantRegionsResponse';
import TenantResponse from '../model/TenantResponse';

/**
* AdminTenant service.
* @module api/AdminTenantApi
* @version v1
*/
export default class AdminTenantApi {

    /**
    * Constructs a new AdminTenantApi. 
    * @alias module:api/AdminTenantApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TenantAttributeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminTenantAttributesGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminTenantAttributesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'IsSearchable': opts['isSearchable']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TenantAttributeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminTenant/attributes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.isSearchable 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TenantAttributeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminTenantAttributesGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminTenantAttributesGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TenantRegionsResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminTenantRegionsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminTenantRegionsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TenantRegionsResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminTenant/regions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TenantRegionsResponse}
     */
    v1TenantTenantIdAdminAdminTenantRegionsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminTenantRegionsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TenantResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminTenantTenantGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminTenantTenantGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TenantResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminTenant/tenant', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TenantResponse}
     */
    v1TenantTenantIdAdminAdminTenantTenantGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminTenantTenantGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Tenant} opts.tenant 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TenantResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminTenantTenantPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['tenant'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminTenantTenantPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TenantResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminTenant/tenant', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Tenant} opts.tenant 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TenantResponse}
     */
    v1TenantTenantIdAdminAdminTenantTenantPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminTenantTenantPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
