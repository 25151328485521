import AdminBusinessRuleMaxArrivalDateConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMaxArrivalDateConfigApi.js";
import AdminBusinessRuleMaxStayConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMaxStayConfigApi.js";
import AdminBusinessRuleMinArrivalDateConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMinArrivalDateConfigApi.js";
import AdminBusinessRuleMinStayConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMinStayConfigApi.js";
import AdminBusinessRuleMaxActiveResOnAccountConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMaxActiveResOnAccountConfigApi.js";
import AdminBusinessRuleMaxResPerTransactionConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMaxResPerTransactionConfigApi.js";
import AdminBusinessRuleMaxResWithSameStartDateConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleMaxResWithSameStartDateConfigApi.js";
import AdminBusinessRuleWalkInPeriodConfigApi from "@/generatedapiclients/src/api/AdminBusinessRuleWalkInPeriodConfigApi.js";
export default class AdminBusinessRuleService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._maxArrivalDateApi = new AdminBusinessRuleMaxArrivalDateConfigApi();
    this._maxStayApi = new AdminBusinessRuleMaxStayConfigApi();
    this._minArrivalDateApi = new AdminBusinessRuleMinArrivalDateConfigApi();
    this._minStayApi = new AdminBusinessRuleMinStayConfigApi();
    this._maxActiveResApi = new AdminBusinessRuleMaxActiveResOnAccountConfigApi();
    this._maxPerTransactionApi = new AdminBusinessRuleMaxResPerTransactionConfigApi();
    this._maxResSameStartApi = new AdminBusinessRuleMaxResWithSameStartDateConfigApi();
    this._walkInPeriodApi = new AdminBusinessRuleWalkInPeriodConfigApi();
  }

  async getMinimumStay(id) {
    return await this._minStayApi.v1TenantTenantIdAdminAdminBusinessRuleMinStayConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMinimumStay() {
    return await this._minStayApi.v1TenantTenantIdAdminAdminBusinessRuleMinStayConfigGet(
      this._tenantId
    );
  }

  async getMinimumArrivalDate(id) {
    return await this._minArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMinArrivalDateConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMinimumArrivalDate() {
    return await this._minArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMinArrivalDateConfigGet(
      this._tenantId
    );
  }

  async getMaximumStay(id) {
    return await this._maxStayApi.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMaximumStay() {
    return await this._maxStayApi.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigGet(
      this._tenantId
    );
  }

  async getMaximumArrivalDate(id) {
    return await this._maxArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMaxArrivalDateConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMaximumArrivalDate() {
    return await this._maxArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMaxArrivalDateConfigGet(
      this._tenantId
    );
  }

  async getMaxActiveRes(id) {
    return await this._maxActiveResApi.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMaxActiveRes() {
    return await this._maxActiveResApi.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigGet(
      this._tenantId
    );
  }

  async getMaxResPerTransaction(id) {
    return await this._maxPerTransactionApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMaxResPerTransaction() {
    return await this._maxPerTransactionApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigGet(
      this._tenantId
    );
  }

  async getMaxResSameStart(id) {
    return await this._maxResSameStartApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGet(
      id,
      this._tenantId
    );
  }

  async getAllMaxResSameStart() {
    return await this._maxResSameStartApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigGet(
      this._tenantId
    );
  }

  async addMaximumArrivalDate(maximumArrivalDateBusinessRuleRequest) {
    return await this._maxArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMaxArrivalDateConfigPost(
      this._tenantId,
      {
        maximumArrivalDateBusinessRuleRequest: {
          ...maximumArrivalDateBusinessRuleRequest
        }
      }
    );
  }
  async editMaximumArrivalDate(request) {
    return await this._maxArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMaxArrivalDateConfigPut(
      this._tenantId,
      {
        maximumArrivalDateBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMaximumActiveReservations(request) {
    return await this._maxActiveResApi.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPut(
      this._tenantId,
      {
        maximumActiveReservationsOnAccountBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMaximumReservationPerTransaction(request) {
    return await this._maxPerTransactionApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPut(
      this._tenantId,
      {
        maximumReservationsPerTransactionBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMaximumReservationWithSameStartDate(request) {
    return await this._maxResSameStartApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPut(
      this._tenantId,
      {
        maximumReservationsWithSameStartDateBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMaximumStay(request) {
    return await this._maxStayApi.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPut(
      this._tenantId,
      {
        maximumStayBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMinimumArrivalDate(request) {
    return await this._minArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMinArrivalDateConfigPut(
      this._tenantId,
      {
        minimumArrivalDateBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editMinimumStay(request) {
    return await this._minStayApi.v1TenantTenantIdAdminAdminBusinessRuleMinStayConfigPut(
      this._tenantId,
      {
        minimumStayBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async addMinimumArrivalDate(minimumArrivalDateBusinessRuleRequest) {
    return await this._minArrivalDateApi.v1TenantTenantIdAdminAdminBusinessRuleMinArrivalDateConfigPost(
      this._tenantId,
      {
        minimumArrivalDateBusinessRuleRequest
      }
    );
  }
  async addMinimumStay(minimumStayBusinessRuleRequest) {
    return await this._minStayApi.v1TenantTenantIdAdminAdminBusinessRuleMinStayConfigPost(
      this._tenantId,
      {
        minimumStayBusinessRuleRequest
      }
    );
  }
  async addMaximumStay(maximumStayBusinessRuleRequest) {
    return await this._maxStayApi.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPost(
      this._tenantId,
      {
        maximumStayBusinessRuleRequest
      }
    );
  }
  async addMaxActiveTransactionsOnRes(
    maximumActiveReservationsOnAccountBusinessRuleRequest
  ) {
    return await this._maxActiveResApi.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPost(
      this._tenantId,
      {
        maximumActiveReservationsOnAccountBusinessRuleRequest
      }
    );
  }
  async addMaxReservationsPerTransaction(
    maximumReservationsPerTransactionBusinessRuleRequest
  ) {
    return await this._maxPerTransactionApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPost(
      this._tenantId,
      {
        maximumReservationsPerTransactionBusinessRuleRequest
      }
    );
  }
  async addMaxReservationsWithSameStartDate(
    maximumReservationsWithSameStartDateBusinessRuleRequest
  ) {
    return await this._maxResSameStartApi.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPost(
      this._tenantId,
      {
        maximumReservationsWithSameStartDateBusinessRuleRequest
      }
    );
  }
  async getAllAgencyWalkInPeriod() {
    return await this._walkInPeriodApi.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigGet(
      this._tenantId
    );
  }
  async getAgencyWalkInPeriod(id) {
    return await this._walkInPeriodApi.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGet(
      id,
      this._tenantId
    );
  }
  async addAgencyWalkIn(request) {
    return await this._walkInPeriodApi.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPost(
      this._tenantId,
      {
        walkInPeriodBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
  async editAgencyWalkIn(request) {
    return await this._walkInPeriodApi.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPut(
      this._tenantId,
      {
        walkInPeriodBusinessRuleRequest: {
          ...request
        }
      }
    );
  }
}
