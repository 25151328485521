<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title mb-0">Existing Rules</div>
    </div>
    <div class="card-body">
      <b-table
        ref="table"
        striped
        :fields="fields"
        :items="tableData"
        stacked="xl"
        small
        bordered
        sort-icon-left
      >
        <template v-slot:cell(id)="data">
          <button
            class="btn btn-primary btn-sm m-1"
            @click="viewRule(data.item)"
          >
            View
          </button>
          <button
            class="btn btn-primary btn-sm m-1"
            @click="editRule(data.item)"
          >
            Edit
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminBusinessRuleTable",
  props: {
    tableData: Array,
    uniqueFields: { type: Array, default: () => [] }
  },
  data() {
    return {
      baseFields: [
        { key: "referenceId", label: "Reference ID", sortable: true },
        { key: "isActive", sortable: true },
        { key: "startDate", sortable: true, formatter: "formatDate" },
        { key: "endDate", sortable: true, formatter: "formatDate" },
        { key: "dateLastModified", sortable: true, formatter: "formatDate" },
        { key: "salesChannel", label: "Channels", sortable: true }
      ]
    };
  },
  computed: {
    fields() {
      return [
        ...this.baseFields,
        ...this.uniqueFields,
        { key: "id", label: "Actions/Options" }
      ];
    }
  },
  methods: {
    viewRule(item) {
      this.$emit("viewRule", item);
    },
    editRule(item) {
      this.$emit("editRule", item);
    },
    formatDate(date) {
      return this.$options.filters.formatUTCDate(date.toISOString());
    }
  }
};
</script>
