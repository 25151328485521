/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Area from '../model/Area';
import AreaResponse from '../model/AreaResponse';
import BooleanResponse from '../model/BooleanResponse';
import ImageIEnumerableResponse from '../model/ImageIEnumerableResponse';
import Location from '../model/Location';
import LocationIEnumerableResponse from '../model/LocationIEnumerableResponse';
import LocationPointOfInterestIEnumerableResponse from '../model/LocationPointOfInterestIEnumerableResponse';
import LocationResponse from '../model/LocationResponse';
import LookupMinimalModelLookupResponse from '../model/LookupMinimalModelLookupResponse';
import Loop from '../model/Loop';
import LoopResponse from '../model/LoopResponse';
import MapItemsPositionRequest from '../model/MapItemsPositionRequest';
import PointOfInterestDistanceDescriptionIEnumerableResponse from '../model/PointOfInterestDistanceDescriptionIEnumerableResponse';
import Response from '../model/Response';
import StringResponse from '../model/StringResponse';

/**
* AdminLocation service.
* @module api/AdminLocationApi
* @version v1
*/
export default class AdminLocationApi {

    /**
    * Constructs a new AdminLocationApi. 
    * @alias module:api/AdminLocationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Area} opts.area 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AreaResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationAreaPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['area'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationAreaPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AreaResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/area', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Area} opts.area 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AreaResponse}
     */
    v1TenantTenantIdAdminAdminLocationAreaPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationAreaPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PointOfInterestDistanceDescriptionIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationDistancediscriptionsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationDistancediscriptionsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PointOfInterestDistanceDescriptionIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/distancediscriptions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PointOfInterestDistanceDescriptionIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLocationDistancediscriptionsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationDistancediscriptionsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdAreaGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdAreaGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdAreaGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/area', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdAreaGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdAreaGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Number} locationId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.imageId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDeleteWithHttpInfo(tenantId, locationId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDelete");
      }
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDelete");
      }

      let pathParams = {
        'tenantId': tenantId,
        'locationId': locationId
      };
      let queryParams = {
        'imageId': opts['imageId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/delete_image', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Number} locationId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.imageId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDelete(tenantId, locationId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDeleteWithHttpInfo(tenantId, locationId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Location} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Location;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Location}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.description 
     * @param {File} opts.formFile 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StringResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdImagePostWithHttpInfo(locationId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdImagePost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdImagePost");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
        'title': opts['title'],
        'description': opts['description']
      };
      let headerParams = {
      };
      let formParams = {
        'formFile': opts['formFile']
      };

      let authNames = ['bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = StringResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/image', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.description 
     * @param {File} opts.formFile 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StringResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdImagePost(locationId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdImagePostWithHttpInfo(locationId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ImageIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdImagesGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdImagesGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdImagesGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ImageIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/images', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ImageIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdImagesGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdImagesGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LookupMinimalModelLookupResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdLoopGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdLoopGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdLoopGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LookupMinimalModelLookupResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/loop', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LookupMinimalModelLookupResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdLoopGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdLoopGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MapItemsPositionRequest} opts.mapItemsPositionRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPutWithHttpInfo(locationId, tenantId, opts) {
      opts = opts || {};
      let postBody = opts['mapItemsPositionRequest'];
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPut");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPut");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/map_item_positions', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MapItemsPositionRequest} opts.mapItemsPositionRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPut(locationId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPutWithHttpInfo(locationId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationPointOfInterestIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationPointOfInterestIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/{locationId}/pointsofinterest', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationPointOfInterestIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Location} opts.location 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['location'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/location', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Location} opts.location 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLocationPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Location} opts.location 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['location'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/location', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Location} opts.location 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLocationPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LocationIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLocationsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLocationsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LocationIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/locations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LocationIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminLocationLocationsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminLocationLocationsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Loop} opts.loop 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LoopResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminLocationLoopPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['loop'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminLocationLoopPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LoopResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminLocation/loop', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Loop} opts.loop 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LoopResponse}
     */
    v1TenantTenantIdAdminAdminLocationLoopPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminLocationLoopPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
