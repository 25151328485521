/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MaximumReservationsWithSameStartDateBusinessRuleConfigIEnumerableResponse from '../model/MaximumReservationsWithSameStartDateBusinessRuleConfigIEnumerableResponse';
import MaximumReservationsWithSameStartDateBusinessRuleConfigResponse from '../model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse';
import MaximumReservationsWithSameStartDateBusinessRuleRequest from '../model/MaximumReservationsWithSameStartDateBusinessRuleRequest';

/**
* AdminBusinessRuleMaxResWithSameStartDateConfig service.
* @module api/AdminBusinessRuleMaxResWithSameStartDateConfigApi
* @version v1
*/
export default class AdminBusinessRuleMaxResWithSameStartDateConfigApi {

    /**
    * Constructs a new AdminBusinessRuleMaxResWithSameStartDateConfigApi. 
    * @alias module:api/AdminBusinessRuleMaxResWithSameStartDateConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsWithSameStartDateBusinessRuleConfigIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResWithSameStartDateConfig', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGetWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGet");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsWithSameStartDateBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResWithSameStartDateConfig/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGet(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigIdGetWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsWithSameStartDateBusinessRuleRequest} opts.maximumReservationsWithSameStartDateBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumReservationsWithSameStartDateBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsWithSameStartDateBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResWithSameStartDateConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsWithSameStartDateBusinessRuleRequest} opts.maximumReservationsWithSameStartDateBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsWithSameStartDateBusinessRuleRequest} opts.maximumReservationsWithSameStartDateBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumReservationsWithSameStartDateBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsWithSameStartDateBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResWithSameStartDateConfig', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsWithSameStartDateBusinessRuleRequest} opts.maximumReservationsWithSameStartDateBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsWithSameStartDateBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResWithSameStartDateConfigPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
