/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BusinessRuleLocationConfig from './BusinessRuleLocationConfig';
import BusinessRuleProductClassificationConfig from './BusinessRuleProductClassificationConfig';
import SalesChannelEnum from './SalesChannelEnum';
import UnitEnum from './UnitEnum';

/**
 * The MinimumStayBusinessRuleConfig model module.
 * @module model/MinimumStayBusinessRuleConfig
 * @version v1
 */
class MinimumStayBusinessRuleConfig {
    /**
     * Constructs a new <code>MinimumStayBusinessRuleConfig</code>.
     * @alias module:model/MinimumStayBusinessRuleConfig
     */
    constructor() { 
        
        MinimumStayBusinessRuleConfig.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MinimumStayBusinessRuleConfig</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MinimumStayBusinessRuleConfig} obj Optional instance to populate.
     * @return {module:model/MinimumStayBusinessRuleConfig} The populated <code>MinimumStayBusinessRuleConfig</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MinimumStayBusinessRuleConfig();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('businessRuleTypeId')) {
                obj['businessRuleTypeId'] = ApiClient.convertToType(data['businessRuleTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('seasonTypeId')) {
                obj['seasonTypeId'] = ApiClient.convertToType(data['seasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('validationMessage')) {
                obj['validationMessage'] = ApiClient.convertToType(data['validationMessage'], 'String');
            }
            if (data.hasOwnProperty('enforceByLocation')) {
                obj['enforceByLocation'] = ApiClient.convertToType(data['enforceByLocation'], 'Boolean');
            }
            if (data.hasOwnProperty('businessRuleProductClassifications')) {
                obj['businessRuleProductClassifications'] = ApiClient.convertToType(data['businessRuleProductClassifications'], [BusinessRuleProductClassificationConfig]);
            }
            if (data.hasOwnProperty('businessRuleLocations')) {
                obj['businessRuleLocations'] = ApiClient.convertToType(data['businessRuleLocations'], [BusinessRuleLocationConfig]);
            }
            if (data.hasOwnProperty('unitId')) {
                obj['unitId'] = ApiClient.convertToType(data['unitId'], 'Number');
            }
            if (data.hasOwnProperty('unit')) {
                obj['unit'] = UnitEnum.constructFromObject(data['unit']);
            }
            if (data.hasOwnProperty('minimumStayLength')) {
                obj['minimumStayLength'] = ApiClient.convertToType(data['minimumStayLength'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayMonday')) {
                obj['minimumStayMonday'] = ApiClient.convertToType(data['minimumStayMonday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayTuesday')) {
                obj['minimumStayTuesday'] = ApiClient.convertToType(data['minimumStayTuesday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayWednesday')) {
                obj['minimumStayWednesday'] = ApiClient.convertToType(data['minimumStayWednesday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayThursday')) {
                obj['minimumStayThursday'] = ApiClient.convertToType(data['minimumStayThursday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayFriday')) {
                obj['minimumStayFriday'] = ApiClient.convertToType(data['minimumStayFriday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStaySaturday')) {
                obj['minimumStaySaturday'] = ApiClient.convertToType(data['minimumStaySaturday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStaySunday')) {
                obj['minimumStaySunday'] = ApiClient.convertToType(data['minimumStaySunday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayMemorialDay')) {
                obj['minimumStayMemorialDay'] = ApiClient.convertToType(data['minimumStayMemorialDay'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayJuly4')) {
                obj['minimumStayJuly4'] = ApiClient.convertToType(data['minimumStayJuly4'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayLaborDay')) {
                obj['minimumStayLaborDay'] = ApiClient.convertToType(data['minimumStayLaborDay'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayColumbusDay')) {
                obj['minimumStayColumbusDay'] = ApiClient.convertToType(data['minimumStayColumbusDay'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
MinimumStayBusinessRuleConfig.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
MinimumStayBusinessRuleConfig.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
MinimumStayBusinessRuleConfig.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
MinimumStayBusinessRuleConfig.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
MinimumStayBusinessRuleConfig.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
MinimumStayBusinessRuleConfig.prototype['tenantId'] = undefined;

/**
 * @member {Number} businessRuleTypeId
 */
MinimumStayBusinessRuleConfig.prototype['businessRuleTypeId'] = undefined;

/**
 * @member {Boolean} isActive
 */
MinimumStayBusinessRuleConfig.prototype['isActive'] = undefined;

/**
 * @member {Number} productId
 */
MinimumStayBusinessRuleConfig.prototype['productId'] = undefined;

/**
 * @member {Number} productTypeId
 */
MinimumStayBusinessRuleConfig.prototype['productTypeId'] = undefined;

/**
 * @member {Number} loopId
 */
MinimumStayBusinessRuleConfig.prototype['loopId'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
MinimumStayBusinessRuleConfig.prototype['salesChannel'] = undefined;

/**
 * @member {Number} salesChannelId
 */
MinimumStayBusinessRuleConfig.prototype['salesChannelId'] = undefined;

/**
 * @member {Number} seasonTypeId
 */
MinimumStayBusinessRuleConfig.prototype['seasonTypeId'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
MinimumStayBusinessRuleConfig.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} comments
 */
MinimumStayBusinessRuleConfig.prototype['comments'] = undefined;

/**
 * @member {Date} startDate
 */
MinimumStayBusinessRuleConfig.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
MinimumStayBusinessRuleConfig.prototype['endDate'] = undefined;

/**
 * @member {String} validationMessage
 */
MinimumStayBusinessRuleConfig.prototype['validationMessage'] = undefined;

/**
 * @member {Boolean} enforceByLocation
 */
MinimumStayBusinessRuleConfig.prototype['enforceByLocation'] = undefined;

/**
 * @member {Array.<module:model/BusinessRuleProductClassificationConfig>} businessRuleProductClassifications
 */
MinimumStayBusinessRuleConfig.prototype['businessRuleProductClassifications'] = undefined;

/**
 * @member {Array.<module:model/BusinessRuleLocationConfig>} businessRuleLocations
 */
MinimumStayBusinessRuleConfig.prototype['businessRuleLocations'] = undefined;

/**
 * @member {Number} unitId
 */
MinimumStayBusinessRuleConfig.prototype['unitId'] = undefined;

/**
 * @member {module:model/UnitEnum} unit
 */
MinimumStayBusinessRuleConfig.prototype['unit'] = undefined;

/**
 * @member {Number} minimumStayLength
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayLength'] = undefined;

/**
 * @member {Number} minimumStayMonday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayMonday'] = undefined;

/**
 * @member {Number} minimumStayTuesday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayTuesday'] = undefined;

/**
 * @member {Number} minimumStayWednesday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayWednesday'] = undefined;

/**
 * @member {Number} minimumStayThursday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayThursday'] = undefined;

/**
 * @member {Number} minimumStayFriday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayFriday'] = undefined;

/**
 * @member {Number} minimumStaySaturday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStaySaturday'] = undefined;

/**
 * @member {Number} minimumStaySunday
 */
MinimumStayBusinessRuleConfig.prototype['minimumStaySunday'] = undefined;

/**
 * @member {Number} minimumStayMemorialDay
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayMemorialDay'] = undefined;

/**
 * @member {Number} minimumStayJuly4
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayJuly4'] = undefined;

/**
 * @member {Number} minimumStayLaborDay
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayLaborDay'] = undefined;

/**
 * @member {Number} minimumStayColumbusDay
 */
MinimumStayBusinessRuleConfig.prototype['minimumStayColumbusDay'] = undefined;






export default MinimumStayBusinessRuleConfig;

