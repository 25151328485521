/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MaximumStayBusinessRuleConfigIEnumerableResponse from '../model/MaximumStayBusinessRuleConfigIEnumerableResponse';
import MaximumStayBusinessRuleConfigResponse from '../model/MaximumStayBusinessRuleConfigResponse';
import MaximumStayBusinessRuleRequest from '../model/MaximumStayBusinessRuleRequest';

/**
* AdminBusinessRuleMaxStayConfig service.
* @module api/AdminBusinessRuleMaxStayConfigApi
* @version v1
*/
export default class AdminBusinessRuleMaxStayConfigApi {

    /**
    * Constructs a new AdminBusinessRuleMaxStayConfigApi. 
    * @alias module:api/AdminBusinessRuleMaxStayConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumStayBusinessRuleConfigIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumStayBusinessRuleConfigIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxStayConfig', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumStayBusinessRuleConfigIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumStayBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGetWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGet");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumStayBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxStayConfig/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumStayBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGet(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigIdGetWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumStayBusinessRuleRequest} opts.maximumStayBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumStayBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumStayBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumStayBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxStayConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumStayBusinessRuleRequest} opts.maximumStayBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumStayBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumStayBusinessRuleRequest} opts.maximumStayBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumStayBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumStayBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumStayBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxStayConfig', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumStayBusinessRuleRequest} opts.maximumStayBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumStayBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxStayConfigPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
