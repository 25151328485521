/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BusinessRuleLocationConfig from './BusinessRuleLocationConfig';
import BusinessRuleProductClassificationConfig from './BusinessRuleProductClassificationConfig';
import SalesChannelEnum from './SalesChannelEnum';

/**
 * The MaximumReservationsWithSameStartDateBusinessRuleConfig model module.
 * @module model/MaximumReservationsWithSameStartDateBusinessRuleConfig
 * @version v1
 */
class MaximumReservationsWithSameStartDateBusinessRuleConfig {
    /**
     * Constructs a new <code>MaximumReservationsWithSameStartDateBusinessRuleConfig</code>.
     * @alias module:model/MaximumReservationsWithSameStartDateBusinessRuleConfig
     */
    constructor() { 
        
        MaximumReservationsWithSameStartDateBusinessRuleConfig.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MaximumReservationsWithSameStartDateBusinessRuleConfig</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MaximumReservationsWithSameStartDateBusinessRuleConfig} obj Optional instance to populate.
     * @return {module:model/MaximumReservationsWithSameStartDateBusinessRuleConfig} The populated <code>MaximumReservationsWithSameStartDateBusinessRuleConfig</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MaximumReservationsWithSameStartDateBusinessRuleConfig();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('businessRuleTypeId')) {
                obj['businessRuleTypeId'] = ApiClient.convertToType(data['businessRuleTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannel')) {
                obj['salesChannel'] = SalesChannelEnum.constructFromObject(data['salesChannel']);
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('seasonTypeId')) {
                obj['seasonTypeId'] = ApiClient.convertToType(data['seasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('validationMessage')) {
                obj['validationMessage'] = ApiClient.convertToType(data['validationMessage'], 'String');
            }
            if (data.hasOwnProperty('enforceByLocation')) {
                obj['enforceByLocation'] = ApiClient.convertToType(data['enforceByLocation'], 'Boolean');
            }
            if (data.hasOwnProperty('businessRuleProductClassifications')) {
                obj['businessRuleProductClassifications'] = ApiClient.convertToType(data['businessRuleProductClassifications'], [BusinessRuleProductClassificationConfig]);
            }
            if (data.hasOwnProperty('businessRuleLocations')) {
                obj['businessRuleLocations'] = ApiClient.convertToType(data['businessRuleLocations'], [BusinessRuleLocationConfig]);
            }
            if (data.hasOwnProperty('length')) {
                obj['length'] = ApiClient.convertToType(data['length'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['tenantId'] = undefined;

/**
 * @member {Number} businessRuleTypeId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['businessRuleTypeId'] = undefined;

/**
 * @member {Boolean} isActive
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['isActive'] = undefined;

/**
 * @member {Number} productId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['productId'] = undefined;

/**
 * @member {Number} productTypeId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['productTypeId'] = undefined;

/**
 * @member {Number} loopId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['loopId'] = undefined;

/**
 * @member {module:model/SalesChannelEnum} salesChannel
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['salesChannel'] = undefined;

/**
 * @member {Number} salesChannelId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['salesChannelId'] = undefined;

/**
 * @member {Number} seasonTypeId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['seasonTypeId'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} comments
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['comments'] = undefined;

/**
 * @member {Date} startDate
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['endDate'] = undefined;

/**
 * @member {String} validationMessage
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['validationMessage'] = undefined;

/**
 * @member {Boolean} enforceByLocation
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['enforceByLocation'] = undefined;

/**
 * @member {Array.<module:model/BusinessRuleProductClassificationConfig>} businessRuleProductClassifications
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['businessRuleProductClassifications'] = undefined;

/**
 * @member {Array.<module:model/BusinessRuleLocationConfig>} businessRuleLocations
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['businessRuleLocations'] = undefined;

/**
 * @member {Number} length
 */
MaximumReservationsWithSameStartDateBusinessRuleConfig.prototype['length'] = undefined;






export default MaximumReservationsWithSameStartDateBusinessRuleConfig;

