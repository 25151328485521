/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MaximumStayBusinessRuleRequest model module.
 * @module model/MaximumStayBusinessRuleRequest
 * @version v1
 */
class MaximumStayBusinessRuleRequest {
    /**
     * Constructs a new <code>MaximumStayBusinessRuleRequest</code>.
     * @alias module:model/MaximumStayBusinessRuleRequest
     */
    constructor() { 
        
        MaximumStayBusinessRuleRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MaximumStayBusinessRuleRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MaximumStayBusinessRuleRequest} obj Optional instance to populate.
     * @return {module:model/MaximumStayBusinessRuleRequest} The populated <code>MaximumStayBusinessRuleRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MaximumStayBusinessRuleRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('businessRuleTypeId')) {
                obj['businessRuleTypeId'] = ApiClient.convertToType(data['businessRuleTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('seasonTypeId')) {
                obj['seasonTypeId'] = ApiClient.convertToType(data['seasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'String');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'String');
            }
            if (data.hasOwnProperty('validationMessage')) {
                obj['validationMessage'] = ApiClient.convertToType(data['validationMessage'], 'String');
            }
            if (data.hasOwnProperty('productClassIds')) {
                obj['productClassIds'] = ApiClient.convertToType(data['productClassIds'], ['Number']);
            }
            if (data.hasOwnProperty('locationIds')) {
                obj['locationIds'] = ApiClient.convertToType(data['locationIds'], ['Number']);
            }
            if (data.hasOwnProperty('enforceByLocation')) {
                obj['enforceByLocation'] = ApiClient.convertToType(data['enforceByLocation'], 'Boolean');
            }
            if (data.hasOwnProperty('maximumStayDays')) {
                obj['maximumStayDays'] = ApiClient.convertToType(data['maximumStayDays'], 'Number');
            }
            if (data.hasOwnProperty('maximumStayPeriodDays')) {
                obj['maximumStayPeriodDays'] = ApiClient.convertToType(data['maximumStayPeriodDays'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
MaximumStayBusinessRuleRequest.prototype['id'] = undefined;

/**
 * @member {Number} businessRuleTypeId
 */
MaximumStayBusinessRuleRequest.prototype['businessRuleTypeId'] = undefined;

/**
 * @member {Boolean} isActive
 */
MaximumStayBusinessRuleRequest.prototype['isActive'] = undefined;

/**
 * @member {Number} productId
 */
MaximumStayBusinessRuleRequest.prototype['productId'] = undefined;

/**
 * @member {Number} productTypeId
 */
MaximumStayBusinessRuleRequest.prototype['productTypeId'] = undefined;

/**
 * @member {Number} loopId
 */
MaximumStayBusinessRuleRequest.prototype['loopId'] = undefined;

/**
 * @member {Number} salesChannelId
 */
MaximumStayBusinessRuleRequest.prototype['salesChannelId'] = undefined;

/**
 * @member {Number} seasonTypeId
 */
MaximumStayBusinessRuleRequest.prototype['seasonTypeId'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
MaximumStayBusinessRuleRequest.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} comments
 */
MaximumStayBusinessRuleRequest.prototype['comments'] = undefined;

/**
 * @member {String} startDate
 */
MaximumStayBusinessRuleRequest.prototype['startDate'] = undefined;

/**
 * @member {String} endDate
 */
MaximumStayBusinessRuleRequest.prototype['endDate'] = undefined;

/**
 * @member {String} validationMessage
 */
MaximumStayBusinessRuleRequest.prototype['validationMessage'] = undefined;

/**
 * @member {Array.<Number>} productClassIds
 */
MaximumStayBusinessRuleRequest.prototype['productClassIds'] = undefined;

/**
 * @member {Array.<Number>} locationIds
 */
MaximumStayBusinessRuleRequest.prototype['locationIds'] = undefined;

/**
 * @member {Boolean} enforceByLocation
 */
MaximumStayBusinessRuleRequest.prototype['enforceByLocation'] = undefined;

/**
 * @member {Number} maximumStayDays
 */
MaximumStayBusinessRuleRequest.prototype['maximumStayDays'] = undefined;

/**
 * @member {Number} maximumStayPeriodDays
 */
MaximumStayBusinessRuleRequest.prototype['maximumStayPeriodDays'] = undefined;






export default MaximumStayBusinessRuleRequest;

