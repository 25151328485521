/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import WalkInPeriodBusinessRuleRequest from '../model/WalkInPeriodBusinessRuleRequest';
import WalkInPeriodRuleConfigIEnumerableResponse from '../model/WalkInPeriodRuleConfigIEnumerableResponse';
import WalkInPeriodRuleConfigResponse from '../model/WalkInPeriodRuleConfigResponse';

/**
* AdminBusinessRuleWalkInPeriodConfig service.
* @module api/AdminBusinessRuleWalkInPeriodConfigApi
* @version v1
*/
export default class AdminBusinessRuleWalkInPeriodConfigApi {

    /**
    * Constructs a new AdminBusinessRuleWalkInPeriodConfigApi. 
    * @alias module:api/AdminBusinessRuleWalkInPeriodConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WalkInPeriodRuleConfigIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WalkInPeriodRuleConfigIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleWalkInPeriodConfig', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WalkInPeriodRuleConfigIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WalkInPeriodRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGetWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGet");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WalkInPeriodRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleWalkInPeriodConfig/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WalkInPeriodRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGet(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigIdGetWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/WalkInPeriodBusinessRuleRequest} opts.walkInPeriodBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WalkInPeriodRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['walkInPeriodBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WalkInPeriodRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleWalkInPeriodConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/WalkInPeriodBusinessRuleRequest} opts.walkInPeriodBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WalkInPeriodRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/WalkInPeriodBusinessRuleRequest} opts.walkInPeriodBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WalkInPeriodRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['walkInPeriodBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = WalkInPeriodRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleWalkInPeriodConfig', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/WalkInPeriodBusinessRuleRequest} opts.walkInPeriodBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WalkInPeriodRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleWalkInPeriodConfigPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
