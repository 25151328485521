/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MaximumReservationsPerTransactionBusinessRuleConfigIEnumerableResponse from '../model/MaximumReservationsPerTransactionBusinessRuleConfigIEnumerableResponse';
import MaximumReservationsPerTransactionBusinessRuleConfigResponse from '../model/MaximumReservationsPerTransactionBusinessRuleConfigResponse';
import MaximumReservationsPerTransactionBusinessRuleRequest from '../model/MaximumReservationsPerTransactionBusinessRuleRequest';

/**
* AdminBusinessRuleMaxResPerTransactionConfig service.
* @module api/AdminBusinessRuleMaxResPerTransactionConfigApi
* @version v1
*/
export default class AdminBusinessRuleMaxResPerTransactionConfigApi {

    /**
    * Constructs a new AdminBusinessRuleMaxResPerTransactionConfigApi. 
    * @alias module:api/AdminBusinessRuleMaxResPerTransactionConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsPerTransactionBusinessRuleConfigIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResPerTransactionConfig', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGetWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGet");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsPerTransactionBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResPerTransactionConfig/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGet(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigIdGetWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsPerTransactionBusinessRuleRequest} opts.maximumReservationsPerTransactionBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumReservationsPerTransactionBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsPerTransactionBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResPerTransactionConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsPerTransactionBusinessRuleRequest} opts.maximumReservationsPerTransactionBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsPerTransactionBusinessRuleRequest} opts.maximumReservationsPerTransactionBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumReservationsPerTransactionBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumReservationsPerTransactionBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxResPerTransactionConfig', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumReservationsPerTransactionBusinessRuleRequest} opts.maximumReservationsPerTransactionBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumReservationsPerTransactionBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxResPerTransactionConfigPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
