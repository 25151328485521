/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MapItemPosition from './MapItemPosition';

/**
 * The MapItemsPositionRequest model module.
 * @module model/MapItemsPositionRequest
 * @version v1
 */
class MapItemsPositionRequest {
    /**
     * Constructs a new <code>MapItemsPositionRequest</code>.
     * @alias module:model/MapItemsPositionRequest
     */
    constructor() { 
        
        MapItemsPositionRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MapItemsPositionRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MapItemsPositionRequest} obj Optional instance to populate.
     * @return {module:model/MapItemsPositionRequest} The populated <code>MapItemsPositionRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MapItemsPositionRequest();

            if (data.hasOwnProperty('spotPositions')) {
                obj['spotPositions'] = ApiClient.convertToType(data['spotPositions'], [MapItemPosition]);
            }
            if (data.hasOwnProperty('pointsOfInterestPositions')) {
                obj['pointsOfInterestPositions'] = ApiClient.convertToType(data['pointsOfInterestPositions'], [MapItemPosition]);
            }
            if (data.hasOwnProperty('locationZoomLevel')) {
                obj['locationZoomLevel'] = ApiClient.convertToType(data['locationZoomLevel'], 'Number');
            }
            if (data.hasOwnProperty('locationLatitude')) {
                obj['locationLatitude'] = ApiClient.convertToType(data['locationLatitude'], 'Number');
            }
            if (data.hasOwnProperty('locationLongitude')) {
                obj['locationLongitude'] = ApiClient.convertToType(data['locationLongitude'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/MapItemPosition>} spotPositions
 */
MapItemsPositionRequest.prototype['spotPositions'] = undefined;

/**
 * @member {Array.<module:model/MapItemPosition>} pointsOfInterestPositions
 */
MapItemsPositionRequest.prototype['pointsOfInterestPositions'] = undefined;

/**
 * @member {Number} locationZoomLevel
 */
MapItemsPositionRequest.prototype['locationZoomLevel'] = undefined;

/**
 * @member {Number} locationLatitude
 */
MapItemsPositionRequest.prototype['locationLatitude'] = undefined;

/**
 * @member {Number} locationLongitude
 */
MapItemsPositionRequest.prototype['locationLongitude'] = undefined;






export default MapItemsPositionRequest;

