/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MinimumStayBusinessRuleRequest model module.
 * @module model/MinimumStayBusinessRuleRequest
 * @version v1
 */
class MinimumStayBusinessRuleRequest {
    /**
     * Constructs a new <code>MinimumStayBusinessRuleRequest</code>.
     * @alias module:model/MinimumStayBusinessRuleRequest
     */
    constructor() { 
        
        MinimumStayBusinessRuleRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MinimumStayBusinessRuleRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MinimumStayBusinessRuleRequest} obj Optional instance to populate.
     * @return {module:model/MinimumStayBusinessRuleRequest} The populated <code>MinimumStayBusinessRuleRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MinimumStayBusinessRuleRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('businessRuleTypeId')) {
                obj['businessRuleTypeId'] = ApiClient.convertToType(data['businessRuleTypeId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('loopId')) {
                obj['loopId'] = ApiClient.convertToType(data['loopId'], 'Number');
            }
            if (data.hasOwnProperty('salesChannelId')) {
                obj['salesChannelId'] = ApiClient.convertToType(data['salesChannelId'], 'Number');
            }
            if (data.hasOwnProperty('seasonTypeId')) {
                obj['seasonTypeId'] = ApiClient.convertToType(data['seasonTypeId'], 'Number');
            }
            if (data.hasOwnProperty('paymentTypeId')) {
                obj['paymentTypeId'] = ApiClient.convertToType(data['paymentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('comments')) {
                obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'String');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'String');
            }
            if (data.hasOwnProperty('validationMessage')) {
                obj['validationMessage'] = ApiClient.convertToType(data['validationMessage'], 'String');
            }
            if (data.hasOwnProperty('productClassIds')) {
                obj['productClassIds'] = ApiClient.convertToType(data['productClassIds'], ['Number']);
            }
            if (data.hasOwnProperty('locationIds')) {
                obj['locationIds'] = ApiClient.convertToType(data['locationIds'], ['Number']);
            }
            if (data.hasOwnProperty('enforceByLocation')) {
                obj['enforceByLocation'] = ApiClient.convertToType(data['enforceByLocation'], 'Boolean');
            }
            if (data.hasOwnProperty('unitId')) {
                obj['unitId'] = ApiClient.convertToType(data['unitId'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayLength')) {
                obj['minimumStayLength'] = ApiClient.convertToType(data['minimumStayLength'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayMonday')) {
                obj['minimumStayMonday'] = ApiClient.convertToType(data['minimumStayMonday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayTuesday')) {
                obj['minimumStayTuesday'] = ApiClient.convertToType(data['minimumStayTuesday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayWednesday')) {
                obj['minimumStayWednesday'] = ApiClient.convertToType(data['minimumStayWednesday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayThursday')) {
                obj['minimumStayThursday'] = ApiClient.convertToType(data['minimumStayThursday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayFriday')) {
                obj['minimumStayFriday'] = ApiClient.convertToType(data['minimumStayFriday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStaySaturday')) {
                obj['minimumStaySaturday'] = ApiClient.convertToType(data['minimumStaySaturday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStaySunday')) {
                obj['minimumStaySunday'] = ApiClient.convertToType(data['minimumStaySunday'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayMemorialDay')) {
                obj['minimumStayMemorialDay'] = ApiClient.convertToType(data['minimumStayMemorialDay'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayJuly4')) {
                obj['minimumStayJuly4'] = ApiClient.convertToType(data['minimumStayJuly4'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayLaborDay')) {
                obj['minimumStayLaborDay'] = ApiClient.convertToType(data['minimumStayLaborDay'], 'Number');
            }
            if (data.hasOwnProperty('minimumStayColumbusDay')) {
                obj['minimumStayColumbusDay'] = ApiClient.convertToType(data['minimumStayColumbusDay'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
MinimumStayBusinessRuleRequest.prototype['id'] = undefined;

/**
 * @member {Number} businessRuleTypeId
 */
MinimumStayBusinessRuleRequest.prototype['businessRuleTypeId'] = undefined;

/**
 * @member {Boolean} isActive
 */
MinimumStayBusinessRuleRequest.prototype['isActive'] = undefined;

/**
 * @member {Number} productId
 */
MinimumStayBusinessRuleRequest.prototype['productId'] = undefined;

/**
 * @member {Number} productTypeId
 */
MinimumStayBusinessRuleRequest.prototype['productTypeId'] = undefined;

/**
 * @member {Number} loopId
 */
MinimumStayBusinessRuleRequest.prototype['loopId'] = undefined;

/**
 * @member {Number} salesChannelId
 */
MinimumStayBusinessRuleRequest.prototype['salesChannelId'] = undefined;

/**
 * @member {Number} seasonTypeId
 */
MinimumStayBusinessRuleRequest.prototype['seasonTypeId'] = undefined;

/**
 * @member {Number} paymentTypeId
 */
MinimumStayBusinessRuleRequest.prototype['paymentTypeId'] = undefined;

/**
 * @member {String} comments
 */
MinimumStayBusinessRuleRequest.prototype['comments'] = undefined;

/**
 * @member {String} startDate
 */
MinimumStayBusinessRuleRequest.prototype['startDate'] = undefined;

/**
 * @member {String} endDate
 */
MinimumStayBusinessRuleRequest.prototype['endDate'] = undefined;

/**
 * @member {String} validationMessage
 */
MinimumStayBusinessRuleRequest.prototype['validationMessage'] = undefined;

/**
 * @member {Array.<Number>} productClassIds
 */
MinimumStayBusinessRuleRequest.prototype['productClassIds'] = undefined;

/**
 * @member {Array.<Number>} locationIds
 */
MinimumStayBusinessRuleRequest.prototype['locationIds'] = undefined;

/**
 * @member {Boolean} enforceByLocation
 */
MinimumStayBusinessRuleRequest.prototype['enforceByLocation'] = undefined;

/**
 * @member {Number} unitId
 */
MinimumStayBusinessRuleRequest.prototype['unitId'] = undefined;

/**
 * @member {Number} minimumStayLength
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayLength'] = undefined;

/**
 * @member {Number} minimumStayMonday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayMonday'] = undefined;

/**
 * @member {Number} minimumStayTuesday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayTuesday'] = undefined;

/**
 * @member {Number} minimumStayWednesday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayWednesday'] = undefined;

/**
 * @member {Number} minimumStayThursday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayThursday'] = undefined;

/**
 * @member {Number} minimumStayFriday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayFriday'] = undefined;

/**
 * @member {Number} minimumStaySaturday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStaySaturday'] = undefined;

/**
 * @member {Number} minimumStaySunday
 */
MinimumStayBusinessRuleRequest.prototype['minimumStaySunday'] = undefined;

/**
 * @member {Number} minimumStayMemorialDay
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayMemorialDay'] = undefined;

/**
 * @member {Number} minimumStayJuly4
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayJuly4'] = undefined;

/**
 * @member {Number} minimumStayLaborDay
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayLaborDay'] = undefined;

/**
 * @member {Number} minimumStayColumbusDay
 */
MinimumStayBusinessRuleRequest.prototype['minimumStayColumbusDay'] = undefined;






export default MinimumStayBusinessRuleRequest;

