<template>
  <div class="row">
    <div class="col-md-3 col-12">
      <TextInput
        name="Reference ID"
        id="ruleReferenceId"
        v-model="referenceId"
        :disabled="true"
      />
      <small class="form-text text-muted mb-2"
        >Reference ID of the business rule.</small
      >
    </div>
    <div class="col-md-6 col-sm-12">
      <TextInput
        name="Name"
        id="ruleName"
        placeholder="Enter rule name"
        v-model="rule.name"
        :disabled="true"
      />
      <small class="form-text text-muted mb-2"
        >{{ rule.name }} will be affected by this business rule.</small
      >
    </div>
    <div class="col-md-3 col-sm-12">
      <ValidationProvider
        rules="required"
        name="Is Active"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleIsActive"
        >
          Is Active
          <span class="error">*</span>
        </label>
        <select
          class="form-control form-control-lg"
          id="ruleIsActive"
          v-model="rule.isActive"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option
            v-for="(choice, index) in booleanChoices"
            :key="index"
            :value="choice.value"
            >{{ choice.key }}</option
          >
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Indicates the status of the business rule. Active rules will be enforced
        during the indicated time period.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Product Class"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="productClasses"
          >Product Class</label
        >
        <multiselect
          v-model="rule.productClasses"
          label="name"
          track-by="id"
          :multiple="true"
          id="productClasses"
          ref="productClasses"
          :clear-on-select="false"
          :preserve-search="true"
          open-direction="bottom"
          placeholder="All"
          :options="productClasses"
          :disabled="view"
          v-bind="ariaInput"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" :key="option.id">
              <span>{{ option.name }}</span>
              <i
                tabindex="1"
                @keypress.enter.prevent="remove(option)"
                @mousedown.prevent="remove(option)"
                class="multiselect__tag-icon"
              ></i>
            </span>
          </template>
        </multiselect>

        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Product classes applicable to the business rule. To ignore this field,
        select 'All'. This is a multiselect field.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Product Type"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleProductType"
          >Product Type</label
        >
        <select
          class="form-control form-control-lg"
          id="ruleProductType"
          v-model="rule.productTypeId"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option
            v-for="item in productTypes"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Product types applicable to the business rule. To ignore this field,
        select 'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Location"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleLocation"
          >Park</label
        >
        <multiselect
          v-model="rule.locations"
          label="name"
          track-by="id"
          :multiple="true"
          id="ruleLocation"
          ref="locations"
          :clear-on-select="false"
          :close-on-select="false"
          :preserve-search="true"
          open-direction="bottom"
          placeholder="All"
          :options="locations"
          :disabled="view"
          v-bind="ariaInput"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" :key="option.id">
              <span>{{ option.name }}</span>
              <i
                tabindex="1"
                @keypress.enter.prevent="remove(option)"
                @mousedown.prevent="remove(option)"
                class="multiselect__tag-icon"
              ></i>
            </span>
          </template>
        </multiselect>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Areas applicable to the business rule. To ignore this field, select
        'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider name="Loop" v-slot="{ errors, ariaInput, ariaMsg }">
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleLoop"
          >Loop</label
        >
        <select
          class="form-control form-control-lg"
          id="ruleLoop"
          v-model="rule.loopId"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option v-for="item in loops" :key="item.id" :value="item.id">{{
            item.name
          }}</option>
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Loops applicable to the business rule. To ignore this field, select
        'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Season Type"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleSeasonType"
          >Season Type</label
        >
        <select
          class="form-control form-control-lg"
          id="ruleSeasonType"
          v-model="rule.seasonTypeId"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option v-for="item in seasonTypes" :key="item.id" :value="item.id">{{
            item.name
          }}</option>
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Season types applicable to the business rule. To ignore this field,
        select 'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Business Rule Enforcement"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleEnforement"
          >Business Rule Enforcement</label
        >
        <select
          class="form-control form-control-lg"
          id="ruleEnforement"
          v-model="rule.enforceByLocation"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option
            v-for="(choice, index) in enforcementTypes"
            :key="index"
            :value="choice.value"
            >{{ choice.key }}</option
          >
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Sales Channel"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="ruleSalesChannel"
          >Sales Channel</label
        >
        <select
          class="form-control form-control-lg"
          id="ruleSalesChannel"
          v-model="rule.salesChannelId"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option
            v-for="item in salesChannels"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Sales channels applicable to the business rule. To ignore this field,
        select 'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <ValidationProvider
        name="Payment Type"
        v-slot="{ errors, ariaInput, ariaMsg }"
      >
        <label
          @click="$refs.select.focus()"
          :class="{ error: errors[0] }"
          for="rulePaymentType"
          >Payment Type</label
        >
        <select
          class="form-control form-control-lg"
          id="rulePaymentType"
          v-model="rule.paymentTypeId"
          v-bind="ariaInput"
          :disabled="view"
        >
          <option
            v-for="item in paymentTypes"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
          <li
            v-for="(error, index) in errors"
            :key="index"
            class="error"
            v-bind="ariaMsg"
          >
            {{ error }}
          </li>
        </ul>
      </ValidationProvider>
      <small class="form-text text-muted mb-2">
        Payment types applicable to the business rule. To ignore this field,
        select 'All'.
      </small>
    </div>
    <div class="col-md-6 col-sm-12">
      <DateInput
        vid="startDate"
        rules="required|isDate"
        name="Start Date"
        id="ruleStartDate"
        v-model="rule.startDate"
        :disabled="view"
      />
      <small class="form-text text-muted mb-2"
        >Start (effective) date of the business rule.</small
      >
    </div>
    <div class="col-md-6 col-sm-12">
      <DateInput
        vid="endDate"
        rules="required|isDate|departureDate:@startDate"
        name="End Date"
        :dateDisabledFn="minDepartureDate"
        :initialDate="initialDate"
        id="ruleEndDate"
        v-model="rule.endDate"
        :disabled="view"
      />
      <small class="form-text text-muted mb-2"
        >End date of the business rule.</small
      >
    </div>
    <div class="col-md-6 col-sm-12">
      <TextInput
        rules="required"
        name="Validation Message"
        id="ruleValidationMessage"
        placeholder="Enter validation message"
        rows="5"
        v-model="rule.validationMessage"
        :disabled="view"
      />
      <small class="form-text text-muted mb-2"
        >Validation message to be displayed if rule is violated.</small
      >
    </div>
    <div class="col-md-6 col-sm-12">
      <TextInput
        rules="max:9000"
        name="Comments"
        placeholder="Enter comments"
        id="ruleComment"
        v-model="rule.comments"
        rows="5"
        :disabled="view"
      />
      <small class="form-text text-muted mb-2"
        >Additional comments about the business rule.</small
      >
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import Multiselect from "vue-multiselect";

export default {
  name: "AdminCommonBusinessRuleFields",
  props: {
    rule: Object,
    referenceId: String,
    view: { type: Boolean, default: false },
    fieldOptionOverrides: { type: Array, default: null },
    getAllLocations: { type: Boolean, default: false }
  },
  components: {
    ValidationProvider,
    TextInput,
    DateInput,
    Multiselect
  },
  data() {
    return {
      booleanChoices: [
        { key: "Yes", value: true },
        { key: "No", value: false }
      ],
      baseOptions: [{ id: "", name: "All" }],
      products: [],
      productTypes: [],
      paymentTypes: [],
      locations: [],
      loops: [],
      salesChannels: [],
      seasonTypes: [],
      productClasses: [],
      enforcementTypes: [
        { key: "Individual Park", value: true },
        { key: "Entire Tenancy", value: false }
      ]
    };
  },
  computed: {
    initialDate() {
      if (this.rule.startDate) {
        return moment(this.rule.startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    minDepartureDate(ymd) {
      if (this.rule.startDate) {
        return moment(this.rule.startDate, "MM-DD-YYYY").isSameOrAfter(
          ymd,
          "day"
        );
      } else {
        return false;
      }
    },
    setFieldOverrides() {
      this.fieldOptionOverrides?.forEach(x => {
        this[x.optionsArray] = this[x.optionsArray].filter(y =>
          x.optionNames.includes(y.name)
        );
      });
    },
    async initialize() {
      const dataService = new AdminLookupService(this.tenantId);
      const productService = new AdminProductManagementService(this.tenantId);
      const locationService = new AdminLocationService(this.tenantId);
      // this.products = this.baseOptions.concat(
      //   await dataService.getAllProducts()
      // );
      this.productTypes = this.baseOptions;
      // this.productTypes = this.baseOptions.concat(
      //   await dataService.getAllProductTypes()
      // );
      this.productClasses = await productService.getAllProductClasses();
      this.productClasses = this.productClasses.map(x => {
        return { ...x, name: `${x.name} - P${(x.id + "").padStart(3, "0")}` };
      });

      this.paymentTypes = this.baseOptions;
      // this.paymentTypes = this.baseOptions.concat(
      //   await dataService.getAllPaymentTypes()
      // );
      this.locations = this.getAllLocations
        ? this.baseOptions.concat(
            (await locationService.getLocations()).data.map(x => {
              return { ...x, name: x.longName };
            })
          )
        : this.baseOptions;
      this.loops = this.baseOptions;
      // this.loops = this.baseOptions.concat(await dataService.getAllLoop());
      this.salesChannels = await dataService.getAllSalesChannels();

      this.seasonTypes = this.baseOptions;
      // dataService
      //   .getAllSeasonTypes()
      //   .then(
      //     response => (this.seasonTypes = this.baseOptions.concat(response))
      //   );
    }
  },
  async mounted() {
    await this.initialize();
    this.$refs.productClasses.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.locations.$refs.search.setAttribute("autocomplete", "off");
    this.setFieldOverrides();
  }
};
</script>
