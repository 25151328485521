<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :rules="rules"
    :name="name"
    :class="dateClass"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :bails="false"
    :immediate="immediate"
  >
    <label @click="$refs.input.focus()" :for="id" :class="{ error: errors[0] }">
      <span>{{ name }}</span>
      <span class="error">{{ required ? " *" : "" }}</span>
    </label>
    <div class="d-flex">
      <b-form-datepicker
        @context="setDate"
        button-only
        button-variant="primary"
        :hide-header="true"
        :date-disabled-fn="dateDisabledFn"
        :initial-date="initialDate"
        :no-flip="true"
        :disabled="disabled"
        :value="innerValue"
      ></b-form-datepicker>
      <div style="flex-grow: 1;">
        <input
          :disabled="disabled"
          class="ml-n2 form-control"
          :class="formControlSizeClass"
          :id="id"
          type="text"
          :placeholder="placeholder"
          ref="input"
          v-model="innerValue"
          v-bind="ariaInput"
          v-mask="mask"
        />
      </div>
    </div>
    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
      <li
        v-for="(error, index) in errors"
        :key="index"
        class="error"
        v-bind="ariaMsg"
      >
        {{ error }}
      </li>
    </ul>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import moment from "moment";

export default {
  name: "DateInput",
  directives: { mask },
  components: {
    ValidationProvider
  },
  methods: {
    setDate(ctx) {
      if (ctx.selectedYMD !== "") {
        this.innerValue = moment(ctx.selectedYMD).format(
          this.format ? this.format : "L"
        );
      }
    }
  },
  props: {
    dateClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      type: null,
      default: ""
    },
    size: {
      type: String,
      default: "lg"
    },
    dateDisabledFn: {
      type: Function,
      default: () => false
    },
    initialDate: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: "MM/DD/YYYY"
    },
    mask: {
      type: String,
      default: "##/##/####"
    },
    immediate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    hasValue() {
      return !!this.innerValue;
    },
    formControlSizeClass() {
      return "form-control-" + this.size;
    }
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
