/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import MaximumActiveReservationsOnAccountBusinessRuleConfigIEnumerableResponse from '../model/MaximumActiveReservationsOnAccountBusinessRuleConfigIEnumerableResponse';
import MaximumActiveReservationsOnAccountBusinessRuleConfigResponse from '../model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse';
import MaximumActiveReservationsOnAccountBusinessRuleRequest from '../model/MaximumActiveReservationsOnAccountBusinessRuleRequest';

/**
* AdminBusinessRuleMaxActiveResOnAccountConfig service.
* @module api/AdminBusinessRuleMaxActiveResOnAccountConfigApi
* @version v1
*/
export default class AdminBusinessRuleMaxActiveResOnAccountConfigApi {

    /**
    * Constructs a new AdminBusinessRuleMaxActiveResOnAccountConfigApi. 
    * @alias module:api/AdminBusinessRuleMaxActiveResOnAccountConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumActiveReservationsOnAccountBusinessRuleConfigIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxActiveResOnAccountConfig', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGetWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGet");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumActiveReservationsOnAccountBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxActiveResOnAccountConfig/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGet(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigIdGetWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumActiveReservationsOnAccountBusinessRuleRequest} opts.maximumActiveReservationsOnAccountBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumActiveReservationsOnAccountBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumActiveReservationsOnAccountBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxActiveResOnAccountConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumActiveReservationsOnAccountBusinessRuleRequest} opts.maximumActiveReservationsOnAccountBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumActiveReservationsOnAccountBusinessRuleRequest} opts.maximumActiveReservationsOnAccountBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['maximumActiveReservationsOnAccountBusinessRuleRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = MaximumActiveReservationsOnAccountBusinessRuleConfigResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminBusinessRuleMaxActiveResOnAccountConfig', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/MaximumActiveReservationsOnAccountBusinessRuleRequest} opts.maximumActiveReservationsOnAccountBusinessRuleRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MaximumActiveReservationsOnAccountBusinessRuleConfigResponse}
     */
    v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminBusinessRuleMaxActiveResOnAccountConfigPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
