import AdminLocationApi from "@/generatedapiclients/src/api/AdminLocationApi.js";
import AdminTenantApi from "@/generatedapiclients/src/api/AdminTenantApi.js";
import AdminSpotApi from "@/generatedapiclients/src/api/AdminSpotApi.js";

export default class AdminLocationService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminLocationApi = new AdminLocationApi();
    this._adminTenantApi = new AdminTenantApi();
    this._adminSpotApi = new AdminSpotApi();
  }

  async getLocations() {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationsGet(
      this._tenantId
    );
    return response;
  }
  async getLocation(locationId) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdGet(
      locationId,
      this._tenantId
    );
    return response;
  }
  async saveLocation(location) {
    if (location.id) {
      const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationPut(
        this._tenantId,
        {
          location
        }
      );
      return response;
    }

    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationPost(
      this._tenantId,
      {
        location
      }
    );
    return response;
  }
  async getRegions() {
    const response = await this._adminTenantApi.v1TenantTenantIdAdminAdminTenantRegionsGet(
      this._tenantId
    );
    return response;
  }
  async getLocationPointsOfInterest(locationId) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdPointsofinterestGet(
      locationId,
      this._tenantId
    );
    return response;
  }
  getLocationImages(locationId) {
    return this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdImagesGet(
      locationId,
      this._tenantId
    );
  }
  async getPointsOfInterestDistanceDescriptions() {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationDistancediscriptionsGet(
      this._tenantId
    );
    return response;
  }
  async addLocationImage(locationId, title, file) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdImagePost(
      locationId,
      this._tenantId,
      {
        title: title,
        formFile: file
      }
    );
    return response;
  }
  async deleteLocationImage(locationId, imageId) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdDeleteImageDelete(
      this._tenantId,
      locationId,
      {
        imageId: imageId
      }
    );
    return response;
  }
  async getLoops(locationId) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdLoopGet(
      locationId,
      this._tenantId
    );
    return response;
  }
  async getAreas(locationId) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdAreaGet(
      locationId,
      this._tenantId
    );
    return response;
  }
  async saveArea(area) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationAreaPost(
      this._tenantId,
      {
        area
      }
    );
    return response;
  }
  async saveLoop(loop) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLoopPost(
      this._tenantId,
      {
        loop
      }
    );
    return response;
  }

  async updateMapItemsPosition(locationId, items) {
    const response = await this._adminLocationApi.v1TenantTenantIdAdminAdminLocationLocationIdMapItemPositionsPut(
      locationId,
      this._tenantId,
      {
        mapItemsPositionRequest: items
      }
    );
    return response;
  }

  async getClassificationsWithSpotTypes(locationId) {
    return await this._adminSpotApi.v1TenantTenantIdAdminAdminSpotProductClassificationsGet(
      this._tenantId,
      { locationId }
    );
  }
}
